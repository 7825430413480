<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="itemsDevengamientoCoseguros"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-tooltip left max-width="40%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="primary--text pr-5">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      El proceso de devengamiento determinará el comprobante de
                      la siguiente forma:<br />
                      1° Por nomenclador y condición de IVA <br />
                      2° Por condición de IVA y TODOS los nomencladores <br />
                      3° Por nomenclador y TODAS las condiciones de IVA
                    </span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    @click="openModal()"
                    v-if="canCreate"
                    class="to-right"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar devengamiento de coseguro</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteDevengamientoCoseguro(item.coseCtaCteId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar devengamiento de coseguro</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditConfigDevengamientoCoseguros
        :devengCosegurosProps="devengCosegurosProps"
        :tiposComprobantesProp="tiposComprobantesProp"
        @closeAndReload="closeAndReload"
      ></EditConfigDevengamientoCoseguros>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      max-width="35%"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfigDevengamientoCoseguros from "@/components/modules/cuotas/configuracion/EditConfigDevengamientoCoseguros.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditConfigDevengamientoCoseguros,
    GoBackBtn,
    Ayuda
  },
  name: "ConfigDevengamientoCoseguros",
  data: () => ({
    infoIcon: enums.icons.SNB_INFO,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.DEVENGAMIENTO_COSEGUROS,
    showDeleteModal: false,
    isLoading: false,
    idToDelete: null,
    routeToGo: "DevengamientosCobranzas",
    title: enums.titles.DEVENGAMIENTO_COSEGUROS,
    titleDelete: "¿Eliminar devengamiento de coseguro?",
    rules: rules,
    itemsDevengamientoCoseguros: [],
    devengCosegurosProps: {},
    headers: [
      {
        text: "Tipo de nomenclador",
        align: "start",
        value: "nomNom",
        sortable: true,
        sortDesc: true
      },
      {
        text: "Condición de IVA",
        value: "ivaNom",
        sortable: false
      },
      {
        text: "Tipo de comprobante",
        value: "tCompNom",
        sortable: false,
        width: 150
      },
      {
        text: "Concepto",
        value: "concNom",
        sortable: false
      },
      {
        text: "Punto de venta",
        value: "coseCtaCteSuc",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
    tiposComprobantesProp: []
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTiposComprobantesProp();
    this.loadDevengamientoCoseguro();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getConfigCosegurosCtaCte: "configuracion/getConfigCosegurosCtaCte",
      deleteConfigCosegurosCtaCte: "configuracion/deleteConfigCosegurosCtaCte",
      getTiposComprobantesDeudaIngreso:
        "afiliaciones/getTiposComprobantesDeudaIngreso",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_DEVENGAMIENTO_COSEGURO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_DEVENGAMIENTO_COSEGURO:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_DEVENGAMIENTO_COSEGURO:
            this.canEdit = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTiposComprobantesProp() {
      const tipoComprobante = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantesProp = tipoComprobante;
    },
    async loadDevengamientoCoseguro() {
      this.isLoading = true;
      const data = await this.getConfigCosegurosCtaCte();
      this.itemsDevengamientoCoseguros = data;
      this.isLoading = false;
    },
    deleteDevengamientoCoseguro(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfigCosegurosCtaCte(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDevengamientoCoseguro();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.devengCosegurosProps = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.devengCosegurosProps = {};
      this.loadDevengamientoCoseguro();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
