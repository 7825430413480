<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
        <v-tooltip right max-width="40%">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="primary--text pl-7">
              {{ infoIcon }}
            </v-icon>
          </template>
          <span>
            El proceso de devengamiento determinará el comprobante de la
            siguiente forma:<br />
            1° Por nomenclador y condición de IVA <br />
            2° Por condición de IVA y TODOS los nomencladores <br />
            3° Por nomenclador y TODAS las condiciones de IVA
          </span>
        </v-tooltip>
      </v-card-title>

      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Tipo nomenclador -->
            <v-col cols="6" class="pb-0">
              <v-autocomplete
                v-model="tipoNomencladorSelected"
                :items="tiposNomenclador"
                label="Tipo nomenclador"
                item-value="id"
                item-text="value"
                :rules="rules.requiredAcceptZero"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>

            <!-- Tipo condición iva -->
            <v-col cols="6" class="pb-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                label="Condición de IVA"
                item-text="value"
                item-value="id"
                :items="tiposIva"
                :rules="rules.requiredAcceptZero"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo comprobante -->
            <v-col cols="6" class="pb-0">
              <v-autocomplete
                v-model="tipoComprobanteSelected"
                :items="tiposComprobantesProp"
                return-object
                label="Tipo de comprobante"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                @change="
                  tipoComprobanteSelected !== null &&
                  tipoComprobanteSelected !== undefined
                    ? deshabilitarSucursal(tipoComprobanteSelected)
                    : (disabledSucursal = false)
                "
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Concepto -->
            <v-col cols="6" class="pb-0">
              <v-autocomplete
                v-model="conceptoSelected"
                :items="conceptos"
                label="Concepto"
                item-text="concNom"
                item-value="concId"
                :rules="rules.required"
                outlined
                dense
                clearable
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Punto de venta -->
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="sucursal"
                type="text"
                outlined
                v-mask="'####'"
                dense
                :disabled="disabledSucursal"
                :rules="
                  sucursal === undefined
                    ? rules.required
                    : rules.positiveNumberHigher0
                "
                label="Punto de venta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConfigDevengamientoCoseguros",
  directives: { mask },
  props: {
    devengCosegurosProps: {
      type: Object,
      required: false
    },
    tiposComprobantesProp: { type: Array }
  },
  data: () => ({
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    formEditTitle: enums.titles.EDITAR_CONFIGURACION_COSEGUROS,
    newTitle: enums.titles.NUEVA_CONFIGURACION_COSEGUROS,
    tipoIvaSelected: null,
    tiposIva: [],
    conceptos: [],
    tiposNomenclador: [],
    tipoNomencladorSelected: null,
    tipoIvaSelected: null,
    tipoComprobanteSelected: null,
    conceptoSelected: null,
    sucursal: null,
    disabledSucursal: false,
    rules: rules
  }),
  created() {
    this.setSelects();
    if (this.devengCosegurosProps) {
      this.setDevengCoseguros(this.devengCosegurosProps);
    } else {
      this.newDevengamientoCoseguros();
    }
  },
  methods: {
    ...mapActions({
      getTiposIvaAndTodas: "afiliaciones/getTiposIvaAndTodas",
      getConceptos: "configuracion/getConceptos",
      getNomencladores: "afiliaciones/getNomencladores",
      saveCosegurosCtaCte: "configuracion/saveCosegurosCtaCte",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposIva = await this.getTiposIvaAndTodas();
      this.tiposIva = tiposIva;
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
      const tipoNomenclador = await this.getNomencladores();
      this.tiposNomenclador = tipoNomenclador;
      this.tiposNomenclador.unshift({ id: 0, value: "Todas" });
    },
    async setDevengCoseguros() {
      this.coseCtaCteId = this.devengCosegurosProps.coseCtaCteId;
      this.tipoNomencladorSelected =
        this.devengCosegurosProps.nomId == null
          ? 0
          : this.devengCosegurosProps.nomId;
      this.tipoIvaSelected =
        this.devengCosegurosProps.ivaId == null
          ? 0
          : this.devengCosegurosProps.ivaId;
      this.conceptoSelected = this.devengCosegurosProps.concId;
      this.tipoComprobanteSelected = this.devengCosegurosProps.tCompId;
      let tipoComprobante = this.tiposComprobantesProp.find(
        x => x.id == this.tipoComprobanteSelected
      );
      this.deshabilitarSucursal(tipoComprobante);
      this.sucursal = this.devengCosegurosProps.coseCtaCteSuc;
    },

    deshabilitarSucursal(obj) {
      if (obj.bool === true) {
        this.disabledSucursal = true;
        this.sucursal = 9999;
      } else {
        this.disabledSucursal = false;
        if (this.devengCosegurosProps != null) {
          this.sucursal = this.devengCosegurosProps.coseCtaCteSuc;
        }
      }
    },
    async newDevengamientoCoseguros() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        coseCtaCteId: this.devengCosegurosProps?.coseCtaCteId,
        nomId:
          this.tipoNomencladorSelected == 0
            ? null
            : this.tipoNomencladorSelected,
        ivaId: this.tipoIvaSelected == 0 ? null : this.tipoIvaSelected,
        tCompId:
          typeof this.tipoComprobanteSelected === "object"
            ? this.tipoComprobanteSelected.id
            : this.tipoComprobanteSelected,
        concId: this.conceptoSelected,
        coseCtaCteSuc: this.sucursal
      };
      const res = await this.saveCosegurosCtaCte(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
