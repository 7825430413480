var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))]),_c('v-tooltip',{attrs:{"right":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text pl-7"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" El proceso de devengamiento determinará el comprobante de la siguiente forma:"),_c('br'),_vm._v(" 1° Por nomenclador y condición de IVA "),_c('br'),_vm._v(" 2° Por condición de IVA y TODOS los nomencladores "),_c('br'),_vm._v(" 3° Por nomenclador y TODAS las condiciones de IVA ")])])],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposNomenclador,"label":"Tipo nomenclador","item-value":"id","item-text":"value","rules":_vm.rules.requiredAcceptZero,"outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoNomencladorSelected),callback:function ($$v) {_vm.tipoNomencladorSelected=$$v},expression:"tipoNomencladorSelected"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"label":"Condición de IVA","item-text":"value","item-value":"id","items":_vm.tiposIva,"rules":_vm.rules.requiredAcceptZero,"outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoIvaSelected),callback:function ($$v) {_vm.tipoIvaSelected=$$v},expression:"tipoIvaSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposComprobantesProp,"return-object":"","label":"Tipo de comprobante","item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},on:{"change":function($event){_vm.tipoComprobanteSelected !== null &&
                _vm.tipoComprobanteSelected !== undefined
                  ? _vm.deshabilitarSucursal(_vm.tipoComprobanteSelected)
                  : (_vm.disabledSucursal = false)}},model:{value:(_vm.tipoComprobanteSelected),callback:function ($$v) {_vm.tipoComprobanteSelected=$$v},expression:"tipoComprobanteSelected"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.conceptos,"label":"Concepto","item-text":"concNom","item-value":"concId","rules":_vm.rules.required,"outlined":"","dense":"","clearable":""},model:{value:(_vm.conceptoSelected),callback:function ($$v) {_vm.conceptoSelected=$$v},expression:"conceptoSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"type":"text","outlined":"","dense":"","disabled":_vm.disabledSucursal,"rules":_vm.sucursal === undefined
                  ? _vm.rules.required
                  : _vm.rules.positiveNumberHigher0,"label":"Punto de venta","autocomplete":"off"},model:{value:(_vm.sucursal),callback:function ($$v) {_vm.sucursal=$$v},expression:"sucursal"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }